<!-- 机构数据新增 -->
<template>
  <div class="addOrganization">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold">机构数据新增</span>
      <el-button class="el-btn" size="small" @click="goBack"> 返回 </el-button>
    </div>
    <!-- 表单部分 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      id="form-1"
      class=""
      inline
      label-width="150px"
    >
      <el-form-item label="机构编号">
        <el-input v-model="form.number" placeholder="请输入机构编号"></el-input>
      </el-form-item>
      <el-form-item label="助记码" prop="mnemonicCode">
        <el-input
          v-model="form.mnemonicCode"
          placeholder="请输入助记码"
        ></el-input>
      </el-form-item>
      <el-form-item label="所在地区">
        <!-- <el-input v-model="form.location" placeholder="请输入所在地区"></el-input> -->
        <el-select
          v-model="form.location"
          placeholder="请选择所在地区"
          :clearable="true"
        >
          <el-option
            v-for="item in form.locationChoose"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构名称">
        <el-input v-model="form.name" placeholder="请输入机构名称"></el-input>
      </el-form-item>
      <el-form-item label="机构全称">
        <el-input
          v-model="form.fullName"
          placeholder="请输入机构全称"
        ></el-input>
      </el-form-item>
      <el-form-item label="机构详细地址" prop="address">
        <el-input
          v-model="form.address"
          placeholder="请输入机构详细地址"
        ></el-input>
      </el-form-item>
      <el-form-item label="机构负责人">
        <el-input
          v-model="form.responsibility"
          placeholder="请输入机构负责人"
        ></el-input>
      </el-form-item>
      <el-form-item label="监考官">
        <el-input
          v-model="form.exam_teacher"
          placeholder="请输入监考官"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phoneNumber">
        <el-input
          v-model="form.phoneNumber"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="开始合作年份" prop="entryDate">
        <el-date-picker
          v-model="form.entryDate"
          value-forma="yyyy-MM-dd"
          type="date"
          placeholder="请选择合作年份"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="机构网址">
        <el-input
          v-model="form.website"
          placeholder="请输入机构网址"
        ></el-input>
      </el-form-item>
      <el-form-item label="统一信用代码">
        <el-input
          v-model="form.creditCode"
          placeholder="请输入统一信用代码"
        ></el-input>
      </el-form-item>
      <el-form-item label="邮箱">
        <el-input v-model="form.mailbox" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="教学点数量">
        <el-input
          v-model="form.teachingPoint"
          placeholder="请输入教学点数量"
        ></el-input>
      </el-form-item>
      <el-form-item label="教室数量">
        <el-input
          v-model="form.classroom"
          placeholder="请输入教室数量"
        ></el-input>
      </el-form-item>
      <el-form-item label="教室总面积 / 平方">
        <el-input
          v-model="form.grossArea"
          placeholder="请输入教室总面积"
        ></el-input>
      </el-form-item>
      <el-form-item label="学生人数">
        <el-input
          v-model="form.studentNum"
          placeholder="请输入学生人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="员工人数">
        <el-input
          v-model="form.staffNum"
          placeholder="请输入员工人数"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系QQ">
        <el-input
          v-model="form.contactQQ"
          placeholder="请输入联系QQ"
        ></el-input>
      </el-form-item>
      <el-form-item label="考级联系人" prop="contacts">
        <el-input
          v-model="form.contacts"
          placeholder="请输入考级联系人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input
          v-model="form.telephone"
          placeholder="请输入联系电话"
        ></el-input>
      </el-form-item>
      <el-form-item label="微信">
        <el-input v-model="form.weChat" placeholder="请输入微信"></el-input>
      </el-form-item>
      <el-form-item label="开户银行">
        <el-input
          v-model="form.bankName"
          placeholder="请输入开户银行"
        ></el-input>
      </el-form-item>
      <el-form-item label="户名">
        <el-input v-model="form.account" placeholder="请输入户名"></el-input>
      </el-form-item>
      <el-form-item label="账号">
        <el-input v-model="form.book" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="报考科目">
        <el-select
          v-model="form.subject"
          placeholder="请选择报考科目"
          :clearable="true"
        >
          <el-option
            v-for="item in form.subjectChoose"
            :key="item.id"
            :label="item.course_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构登录账号">
        <el-input
          v-model="form.login"
          placeholder="请输入机构登录账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="机构登录密码">
        <el-input
          v-model="form.pwd"
          show-password
          placeholder="请输入机构登录密码"
        ></el-input>
      </el-form-item>
      <el-form-item label="有效期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="form.valid"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          :rows="4"
          type="textarea"
          v-model="form.remark"
          placeholder="请输入备注"
          class="textArea-width"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <div slot="footer" class="dialog-footer">
      <!-- <el-button style="background: #FF7300;color: #FFFFFF;">审 核</el-button> -->
      <el-button
        style="background-color: #18bc9c; color: #ffffff"
        @click="submitForm('form')"
        >提 交</el-button
      >
      <el-button class="btn" @click="resetForm('form')">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        number: "", //机构编号
        mnemonicCode: "", //助记码
        location: "", //所在地区
        locationChoose: [
          { label: "广东省", value: 0 },
          { label: "龙岗区", value: 1 },
          { label: "龙华区", value: 2 },
          { label: "福田区", value: 3 },
          { label: "罗湖区", value: 4 },
          { label: "南山区", value: 5 },
          { label: "宝安区", value: 6 },
          { label: "光明区", value: 7 },
          { label: "盐田区", value: 8 },
          { label: "坪山区", value: 9 },
          { label: "大鹏新区", value: 10 },
          { label: "深汕合作区", value: 11 },
          { label: "港澳台地区", value: 12 },
          { label: "其他", value: 13 },
        ], //所在地区选择
        name: "", //机构名称
        fullName: "", //机构全称
        address: "", //机构详细地址
        responsibility: "", //机构负责人
        phoneNumber: "", //手机号
        entryDate: "", //开始合作年份
        website: "", //机构网址
        creditCode: "", //统一信用代码
        mailbox: "", //邮箱
        teachingPoint: "", //教学点数量
        classroom: "", //教室数量
        grossArea: "", //教室总面积
        studentNum: "", //学生人数
        staffNum: "", //员工人数
        contactQQ: "", //联系QQ
        contacts: "", //考级联系人
        telephone: "", //联系电话
        weChat: "", //微信
        bankName: "", //开户行
        account: "", //户名
        book: "", //账号
        subject: "", //报考科目
        subjectChoose: [], //报考科目选择
        login: "", //机构登录账号
        pwd: "", //机构登录密码
        valid: "", //有效期
        remark: "", // 备注
        exam_teacher: "", //监考官
      },
      rules: {
        mnemonicCode: [
          { required: true, message: "请输入助记码", trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入机构详细地址", trigger: "blur" },
        ],
        phoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        entryDate: [
          { required: true, message: "请选择开始合作年份", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入考级联系人", trigger: "blur" },
        ],
      },
      bool: false,
    };
  },
  created() {
    // 报考科目
    this.$request({
      url: "/api/course/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      console.log("报考科目", res);
      if (res.code == 1) {
        this.form.subjectChoose = res.data.list;
      }
    });
  },
  methods: {
    goBack() {
      //返回
      this.$router.go(-1);
    },
    submitForm(form) {
      //提交
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.bool) {
            return;
          }
          this.bool = true;
          var data = this.form;
          this.$request({
            url: "/api/mechanism/add",
            method: "POST",
            data: {
              number: data.number,
              mcode: data.mnemonicCode,
              area: data.location,
              name: data.name,
              all_name: data.fullName,
              address: data.address,
              principal: data.responsibility,
              mobile: data.phoneNumber,
              start_time: data.entryDate,
              ucc: data.creditCode,
              postcode: data.mailbox,
              teaching_point: data.teachingPoint,
              classroom: data.classroom,
              roomarea: data.grossArea,
              students_num: data.studentNum,
              staff_num: data.staffNum,
              qq: data.contactQQ,
              contacts: data.contacts,
              contacts_number: data.telephone,
              wx: data.weChat,
              bank: data.bankName,
              bank_name: data.account,
              bank_card: data.book,
              course_id: data.subject,
              mechanism_web: data.website,
              mechanism_user: data.login,
              mechanism_pwd: data.pwd,
              effective_time: data.valid,
              remark: data.remark,
              exam_teacher: data.exam_teacher,
            },
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: "新增列表成功",
                  type: "success",
                });
                setTimeout(() => {
                  this.goBack();
                }, 1500);
              } else {
                this.$message({
                  message: res.msg,
                  type: "error",
                });
                setTimeout(() => {
                  this.goBack();
                }, 1500);
              }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(form) {
      //取消
      this.$router.push({
        path: "organization",
      });
      this.$refs[form].resetFields();
    },
  },
};
</script>

<style scoped="scoped">
.addOrganization {
  width: 100%;
  height: 100%;
}
/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 59px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}
/* 表单部分 */
.el-form {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.el-input {
  width: 300px;
  height: 37px !important;
}

.el-select {
  width: 300px;
}

.el-date-editor--daterange.el-input__inner {
  width: 300px;
}
/* 性别选择按钮 */
#form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #18bc9c;
}

#form-1 ::v-deep.el-radio__input.is-checked + .el-radio__label {
  color: #18bc9c;
}

#form-1 ::v-deep .el-radio__inner:hover {
  border-color: #18bc9c;
}

/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  padding-bottom: 20px;
}
.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}
</style>
